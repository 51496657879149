import React from 'react';
import { NavLink } from 'react-router-dom';

class AboutCompany extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 bg-white">
                    <div className="container">
                        <div className="section-content ">
                            <div className="m-service-containt text-black">
                                <div className="row">
                                    <div className="col-md-5 col-sm-12">
                                        <div className="service-about-left">
                                            <div className="mt-media">
                                                <img src={require('./../../images/truck1.png')} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 col-sm-12">
                                        <div className="service-about-right m-b30">
                                            <h3 className="m-t0">In our work we have pride, quality is what we provide.</h3>
                                            <p>At IMC, we provide top-quality concrete solutions for all your mobile needs. Our team of experts has years of experience in the industry and is dedicated to delivering exceptional results. Whether you're looking for residential, commercial, or industrial concrete services, we have the skills and equipment to get the job done right. From concrete foundations and driveways to custom concrete designs, we can handle it all. With IMC, you can trust that your concrete project will be completed on time, within budget, and to the highest standards. Contact us today to learn more about our services and how we can help bring your concrete vision to life.</p>
                                            <div className="call-for-quote-outer">
                                                <div className="call-quote">
                                                    <span>Call For a Quote:</span>
                                                    <h4>(727) 645-9058</h4>
                                                </div>
                                                <div className="call-estimate bg-dark">
                                                <NavLink to={"/contact"} className="site-button-secondry btn-effect">Contact For A Estimate</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default AboutCompany;