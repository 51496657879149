import React from 'react';
import Header from './../Common/Header2';
import Footer from './../Common/Footer2';
import Banner from './../Elements/Banner';
import GoogleMapReact from 'google-map-react';

var bnrimg = require('./../../images/banner/4.jpg');

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class ContactUs extends React.Component {
    render() {
        const defaultProps = {
            center: {
              lat: 34.073280,
              lng: -118.251410
            },
            zoom: 12
        };
        return (
            <>
                <Header />
                <div className="page-content">
                <Banner title="Putting a plan to action, to assure your satisfaction!" pagename="Contact Us" bgimage={bnrimg}/>
                   
                    {/* SECTION CONTENTG START */}
                    <div className="section-full p-tb80 inner-page-padding">
                        {/* LOCATION BLOCK*/}
                        <div className="container">
                            {/* GOOGLE MAP & CONTACT FORM */}
                            <div className="section-content">
                                {/* CONTACT FORM*/}
                                <div className="row">
                                    <div className="col-md-8 col-sm-6">
                                        <form className="contact-form cons-contact-form" method="post" action="form-handler.php">
                                            <div className="contact-one m-b30">
                                                {/* TITLE START */}
                                                <div className="section-head">
                                                    <div className="mt-separator-outer separator-left">
                                                        <div className="mt-separator">
                                                            <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Get</span> In touch</h2>
                                                        </div>
                                                        <p>
                                                        Get in touch now for fast and affordable small batch concrete delivery to Hillsborough, Pasco, Pinellas, Southern Hernando and West Polk counties.</p>
                                                    </div>
                                                </div>
                                                {/* TITLE END */}
                                                <div className="form-group">
                                                    <input name="name" type="text" required className="form-control" placeholder="Name*" />
                                                </div>
                                                <div className="form-group">
                                                    <input name="company" type="text" className="form-control" required placeholder="Please enter your company name (if applicable)" />
                                                </div>
                                                <div className="form-group">
                                                    <input name="phone" type="text" className="form-control" required placeholder="Phone Number*" />
                                                </div>
                                                <div className="form-group">
                                                    <input name="email" type="text" className="form-control" required placeholder="Email*" />
                                                </div>
                                                <div className="form-group">
                                                    <input name="estimate-concrete" type="text" className="form-control" placeholder="Estimated Yards of Concrete" />
                                                </div>
                                                <div className="form-group">
                                                    <textarea name="message" rows={4} className="form-control "  required placeholder="Message" defaultValue={""} />
                                                </div>
                                                <div className="text-right">
                                                    <button name="submit" type="submit" value="Submit" className="site-button btn-effect">submit</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                        <div className="contact-info m-b30">
                                            {/* TITLE START */}
                                            <div className="section-head">
                                                <div className="mt-separator-outer separator-left">
                                                    <div className="mt-separator">
                                                        <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Contact</span> Info</h2>
                                                    </div>
                                                    <p>We will get back to you as soon as we can. We look forward to hearing from you!</p>
                                                </div>
                                            </div>
                                            {/* TITLE END */}
                                            <div className="bg-dark p-a20 text-white">
                                                <div className="mt-icon-box-wraper left p-b40">
                                                    <div className="icon-xs"><i className="fa fa-phone" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0 font-weight-500">Phone number</h5>
                                                        <p>(727) 645-9058</p>
                                                    </div>
                                                </div>
                                                <div className="mt-icon-box-wraper left p-b40">
                                                    <div className="icon-xs"><i className="fa fa-envelope" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0 font-weight-500">Email address</h5>
                                                        <p>info@integrityconcretefl.com</p>
                                                    </div>
                                                </div>
                                                <div className="mt-icon-box-wraper left p-b40">
                                                    <div className="icon-xs"><i className="fa fa-map-marker" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0 font-weight-500">Address info</h5>
                                                        <p>Land O' Lakes, FL 34638</p>
                                                    </div>
                                                </div>
                                                <div className="mt-icon-box-wraper left">
                                                    <div className="icon-xs"><i className="fa-solid fa-calendar" /></div>
                                                    <div className="icon-content">
                                                        <h5 className="m-t0 font-weight-500">Business Hours</h5>
                                                        <p>Monday - Friday: 6AM - 5PM <br />
                                                        Saturday - Sunday: Closed</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="gmap-outline">
                                <div className='google-map'>
                                    <iframe style={{ height: '400px', width: '100%' }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d56234.33017383242!2d-82.51017406113439!3d28.248433497480416!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c2bb2de32d3337%3A0x8c723509b9335acd!2sLand%20O&#39;%20Lakes%2C%20FL!5e0!3m2!1sen!2sus!4v1675979483562!5m2!1sen!2sus" />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* SECTION CONTENT END */}
                </div>


                <Footer />

            </>
        );
    };
};

export default ContactUs;