import React from 'react';
import Header from './../Common/Header2';
import Footer from './../Common/Footer2';
import Slider from './../Elements/Slider3';
import About from './../Elements/About3';
import OurServices from './../Elements/OurServices';
import Callus from './../Elements/Callus';
import Testimonials from './../Elements/Testimonials';
import ClientsLogo from '../Elements/ClientsLogo';

class Home extends React.Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content">
                    <Slider />
                    <About />
                    <OurServices />
                    <Callus />
                    <Testimonials />
                    <ClientsLogo />
                </div>
                <Footer />
            </>
        );
    };
};

export default Home;